<template>
  <section class="project-house-construction">
    <section class="hero-small">
      <h2>
        Configurez votre projet de construction
      </h2>
      <h3>
        Le plus court chemin entre vous et votre maison
      </h3>
      <configurator-search/>
    </section>
    <div class="grid-container" v-if="departments && departments.length">
      <h1>Où faire construire avec Maisons Alysia</h1>

      <div
        v-for="department in departments"
        :key="department.slug"
        class="grid-x row card-city-large-container"
      >
        <div class="cell">
          <div class="card-city-large">
            <div>
              <img :src="department.content.presentationImage.filename" :alt="department.content.presentationImage.alt" />
            </div>
            <div>
              <h3>{{ department.name }}</h3>
              <p>{{ department.content.introductionText.substring(0, 140) }}...</p>
              <router-link :to="{ name: 'department', params: { dpt: `${department.slug}-${department.content.number}` } }">En savoir plus...</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Storyblok from '@/services/storyblok';

import ConfiguratorSearch from '../../components/general/ConfiguratorSearch.vue';

export default {
  name: 'project-house-construction',
  components: {
    ConfiguratorSearch,
  },
  data() {
    return {
      departments: null,
      isCityValidated: null,
      selectedPostalCode: null,
      postalCodeInseeNumber: null,
      isModalOpen: false,
    };
  },
  async mounted() {
    await this.getDepartments();
  },
  methods: {
    async getDepartments() {
      try {
        const response = await Storyblok.get('cdn/stories/', {
          version: 'published',
          starts_with: 'departments/',
        });
        this.departments = response.data.stories.sort((a, b) => a.content.number - b.content.number);
      } catch (err) {
        throw err;
      }
    },
    submitCity() {
      if (this.postalCodeInseeNumber && this.postalCodeInseeNumber.length > 4) {
        this.isCityValidated = true;
      }
    },
    scrollToTop() {
      const top = document.querySelector('.home');
      top.scrollIntoView({ behavior: 'smooth' });
    },
    resetSelectedPostalCode() {
      this.selectedPostalCode = null;
      this.postalCodeInseeNumber = null;
    },
    setSelectedPostalCode(value) {
      this.selectedPostalCode = value.label;
      this.postalCodeInseeNumber = value.name;
    },
  },
};
</script>

<style lang="sass">
  .project-house-construction
    padding: 0 0 4rem
    background: $medium-bg
    .hero-small
      @include hero-small
      background-image: linear-gradient(180deg, rgba(10, 10, 10, .6) 0%, rgba(32, 32, 32, .2) 100%), url(~@/assets/img/pages/bedroom.png)
      > h2
        margin: 0 auto
      > h3
        margin: 0 auto 2.5rem auto
      @media (max-width: 768px)
        min-height: 650px
    .configurator-search
      width: 65vw
      min-width: 300px
      @media (max-width: 1024px)
        width: 100%
    .card-find-house
      @include card-find-house
    h1
      text-align: center
      margin: 90px 50px

    .card-city-large
      @include card-city-large
      h3
        color: $subtitle

    .card-city-large-container
      margin-top: 20px

    @media (max-width: 768px)
      h1
        margin: 70px 30px
      .card-city-large
        h3
          margin-bottom: 0.5rem
</style>
